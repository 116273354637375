import React from "react"
import styles from "../css/box.module.css"
const Box = ({ title, info, children, icon = "" }) => {
  let finalBannerStyle = styles.banner 
  let finalContentClass = styles.contentBannerDefault
  return (
    <div className={finalContentClass}>
      <div className={styles.contentBanner2}>
        <p>{icon}</p>
      </div>
      <div className={finalBannerStyle}>
        <h1>{title}</h1>
        <p dangerouslySetInnerHTML={{ __html: info }} />
        {children}
      </div>      
    </div>
  )
}
export default Box
