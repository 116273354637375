import React from 'react'
import StyledHero from "../components/StyleHero"
import styles3 from "../css/banner.module.css"
import stylesBox from "../css/box.module.css"
import Banner from "../components/Banner"
import Box from "../components/Box"
import {useStaticQuery, graphql} from 'gatsby'
import {Icon} from 'antd'
const getWork = graphql`query{
  about:allContentfulWork(sort:{fields: order}){
    edges{
      node{
        title
        contentful_id
        iconName
        contentText{
          childContentfulRichText {
            html
          }
        }
        alignLeft
      }
    }
  }
}`
const Works = () => {
  const response = useStaticQuery(getWork)
  const worksQuery = response.about.edges
  return (
    <div className={stylesBox.main}>
        {worksQuery.map(({node}, index) => {
          let finalText = node.contentText.childContentfulRichText.html
          let finalIcon = node.iconName
          return (
            <Box title={node.title} info={finalText} icon={<Icon type={finalIcon} />}></Box>
          )
        })}
    </div>
  )
}
export default Works
